@font-face {
  font-family: "Montserrat Extra-Bold";
  font-display: block;
  font-weight: 800;
  src: url("../fonts/Montserrat/Montserrat-ExtraBold.woff2") format('woff2');
}
@font-face {
  font-family: "Montserrat Bold";
  font-display: auto;
  font-weight: 700;
  src: url("../fonts/Montserrat/Montserrat-Bold.woff2") format('woff2');
}
@font-face {
  font-family: "Montserrat Medium";
  font-display: block;
  font-weight: 500;
  src: url("../fonts/Montserrat/Montserrat-Medium.woff2") format('woff2');
}
@font-face {
  font-family: "Montserrat";
  font-display: block;
  font-weight: 400;
  src: url("../fonts/Montserrat/Montserrat-Normal.woff2") format('woff2');
}

html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  font-family: "Montserrat", sans-serif;
}

:root {
  --font-display: block;
  --font-body: "Montserrat";
}
