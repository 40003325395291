.root {
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hiddenScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

//
.nc-will-change-transform {
  will-change: transform;
}
.nc-will-change-top {
  will-change: top;
}
//

.nc-SectionClientSay .glide__bullet--active {
  @apply bg-neutral-700;
}
.nc-GallerySlider .glide__bullet--active {
  @apply bg-white w-2 h-2;
}

//
.nc-card-title {
  @apply transition-colors  hover:text-primary-900 dark:hover:text-primary-300 duration-75;
}

//
.nc-PostCardCommentBtn,
.nc-PostCardLikeAction,
.nc-CommentCardLikeReply > button {
  &:hover {
    span {
      color: inherit;
    }
  }
}

//
.nc-box-has-hover {
  @apply bg-white rounded-3xl border border-neutral-200 border-opacity-70 hover:bg-neutral-50 hover:shadow-xl hover:border-transparent transition-shadow;
}

.nc-dark-box-bg-has-hover {
  @apply dark:bg-neutral-800 dark:bg-opacity-30  dark:border dark:border-neutral-700 dark:hover:bg-neutral-800 dark:hover:shadow-2xl;
}

.nc-dark-box-bg {
  @apply dark:bg-neutral-800 dark:bg-opacity-30  dark:border dark:border-neutral-700 dark:shadow-2xl;
}

//
.nc-origin-100 {
  transform-origin: 100% 50% 0px;
}
.nc-origin-50 {
  transform-origin: 50% 50% 0px;
}

// HERO
.nc-hero-field-padding {
  @apply py-3 px-4 md:py-4 md:px-7 xl:py-6 xl:px-10;
}

.nc-flex-1 {
  flex: 1 0 0%;
}

.nc-flex-1\.5 {
  flex: 1.5 0 0%;
}
.nc-flex-1\.5-auto {
  flex: 1.5 0 auto;
}

.nc-flex-2 {
  flex: 2 0 0%;
}

.nc-flex-2-auto {
  flex: 2 0 auto;
}

.nc-flex-2\.5 {
  flex: 2.5 0 0%;
}

@screen lg {
  .lg\:nc-flex-1\.5 {
    flex: 1.5 0 0%;
  }
  .lg\:nc-flex-1\.5-auto {
    flex: 1.5 0 auto;
  }

  .lg\:nc-flex-2 {
    flex: 2 0 0%;
  }

  .lg\:nc-flex-2-auto {
    flex: 2 0 auto;
  }

  .lg\:nc-flex-2\.5 {
    flex: 2.5 0 0%;
  }
}

.nc-icon-field {
  @apply w-5 h-5 lg:w-7 lg:h-7;
}
//
button.gm-control-active.gm-fullscreen-control {
  @apply rounded-xl #{!important};
}

//
.nc-FiveStartIconForRate {
  svg:hover ~ svg {
    @apply text-neutral-300;
  }
}

// LISTING PAGE DETAIL
.listingSection__wrap {
  @apply w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8;
}

//
input[type='time']::-webkit-calendar-picker-indicator {
  @apply dark:bg-neutral-500 rounded-md;
}

[type='button'] {
  -webkit-appearance: none !important;
}

// NOTE: the order of these styles DO matter

.DateInput_fang {
  display: none;
}

.DateRangePicker {
  @apply w-full;
}

.DateRangePickerInput {
  background-color: transparent;
}
.DateInput,
.DateRangePickerInput > *:not(.DateRangePicker_picker) {
  opacity: 0 !important;
  z-index: -1 !important;
}

//
.SingleDatePicker {
  @apply w-full;
}
.SingleDatePickerInput {
  background: transparent;
}
//

.DayPickerNavigation_button__horizontalDefault {
  @apply p-0 flex items-center justify-center w-9 h-9 rounded-full bg-transparent text-neutral-700 dark:text-neutral-200 focus:bg-transparent;
  svg {
    fill: currentColor;
  }
}

.DayPickerNavigation_svg__horizontal {
  @apply w-4 h-4;
}

.CalendarMonth_caption strong {
  @apply font-semibold text-neutral-800 dark:text-neutral-100;
}
.DayPicker_weekHeader {
  @apply text-neutral-500 dark:text-neutral-400;
}
.DateRangePicker_picker,
.SingleDatePicker_picker {
  @apply top-full mt-3 #{!important};
}

.SingleDatePicker_picker,
.DateRangePicker_picker,
.CalendarMonthGrid,
.CalendarMonth {
  background-color: transparent !important;
}
.DayPicker__withBorder,
.DateRangePicker_picker {
  @apply bg-white dark:bg-neutral-800 shadow-xl rounded-3xl #{!important};
}

.CalendarDay__default.CalendarDay__blocked_out_of_range,
.CalendarDay__default.CalendarDay__blocked_out_of_range:active,
.CalendarDay__default.CalendarDay__blocked_out_of_range:hover {
  @apply border border-transparent hover:bg-transparent text-neutral-300 dark:text-neutral-500 cursor-default;
}

// Will edit everything selected including everything between a range of dates
.CalendarDay__default {
  @apply w-12 h-12 border border-transparent bg-transparent text-neutral-800 dark:text-neutral-200;
  &:hover {
    @apply bg-neutral-200 border border-neutral-200 dark:bg-neutral-700 dark:border-neutral-700;
  }
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  @apply bg-neutral-800 dark:bg-neutral-300 border border-neutral-800 text-neutral-100 dark:text-neutral-900;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span,
.CalendarDay__selected_span {
  @apply bg-neutral-100 dark:bg-neutral-700 border border-neutral-100 dark:border-neutral-700 text-neutral-700;
}

.CalendarDay__selected_span:hover {
  @apply bg-neutral-200 dark:bg-neutral-6000 border-neutral-200 dark:border-neutral-6000 text-neutral-700 dark:text-neutral-100;
}

//
.listingSection__wrap__DayPickerRangeController {
  .DayPicker__withBorder {
    @apply shadow-none mx-auto xl:ml-0  border-neutral-200 dark:border-neutral-700 rounded-xl dark:bg-transparent;
  }
}

//
.nc-SetYourAvailabilityData {
  .CalendarDay__highlighted_calendar {
    @apply bg-neutral-200 dark:bg-neutral-6000 border-neutral-200 dark:border-neutral-6000 text-neutral-700 dark:text-neutral-100 relative;
    &::after {
      content: '';
      width: 1px;
      height: 30px;
      left: 50%;
      transform: rotate(-40deg);
      background-color: black;
      position: absolute;
    }
  }
}
.react-time-picker__wrapper [type='number'],
.react-date-picker__wrapper [type='number'] {
  font-size: inherit !important;
  background-color: transparent !important;
}

.react-date-picker__wrapper [type='number'] {
  padding: 0 !important;
}
.react-time-picker__inputGroup__input {
  padding: 0 1px !important;
}

.react-time-picker__inputGroup__input--hasLeadingZero {
  padding-left: calc(1px + 0.54em) !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}
