.nc-MainNav1 {
  &.notOnTop {
    @apply bg-white dark:bg-neutral-900 backdrop-blur-2xl bg-opacity-70 dark:bg-opacity-60 shadow-sm dark:border-b dark:border-neutral-700;
  }
}

* {
  font-family: 'Montserrat';
}

.greether-button {
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 21.94px !important;
  border-color: #ee2076;
  border-width: 1px;
  padding: 8px 24px !important;
}

.greether-button:disabled {
  color: #ffffff !important;
  border-color: #6b7280 !important;
  background-color: #6b7280 !important;
}

.greether-button:disabled:hover {
  color: #ffffff !important;
  border-color: #6b7280 !important;
  background-color: #6b7280 !important;
}

.greether-profile-button {
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 21.94px !important;
  border-color: #ee2076;
  border-width: 1px;
  padding: 8px 24px !important;
}

.greether-profile-button:disabled {
  color: #ffffff !important;
  border-color: #6b7280 !important;
  background-color: #6b7280 !important;
}

.greether-profile-button:disabled:hover {
  color: #ffffff !important;
  border-color: #6b7280 !important;
  background-color: #6b7280 !important;
}

.login-button {
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 21.94px !important;
  color: #6b7280 !important;
  border-color: #6b7280 !important;
  border-width: 2px !important;
  margin-right: 24px !important;
  padding: 8px 24px !important;
}

.login-button:hover {
  color: #3c2663 !important;
  border-color: #3c2663 !important;
  background: transparent !important;
}

.greether-purple {
  color: rgb(60, 38, 99) !important;
}

.menu-option-active {
  color: #ee2076 !important;
  font-family: 'Montserrat Extra-Bold' !important;
}

.menu-option:hover {
  color: #ee2076 !important;
  font-family: 'Montserrat Extra-Bold' !important;
}

.menu-option {
  font-weight: 500;
  font-size: 18px;
  line-height: 21.94px;
  padding-right: 32px;
  color: #000000;
  font-family: 'Montserrat Medium';
}

.bg-primary-6000:hover {
  background: transparent !important;
  color: #ee2076 !important;
  border-color: #ee2076;
}

.input-tips-email {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-top-width: 0 !important;
  border-bottom-width: 1px !important;
  border-bottom-color: #3c2663 !important;
}

.input-tips-email::placeholder {
  color: #3c2663;
}

#questionnaire input {
  box-shadow: none !important;
}
#booking input {
  box-shadow: none !important;
}

#profile input {
  box-shadow: none !important;
}

.callendly-button {
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 21.94px !important;
  padding: 8px !important;
  min-width: 200px;
  height: 50px !important;
  border-color: #ee2076;
  border-width: 1px;
  padding: 8px 24px !important;
}

.callendly-button:disabled {
  color: #ffffff !important;
  border-color: #6b7280 !important;
  background-color: #6b7280 !important;
}

.callendly-button:disabled:hover {
  color: #ffffff !important;
  border-color: #6b7280 !important;
  background-color: #6b7280 !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #111827 !important;
}
